import React, { useEffect, useState } from "react";
import { TextField, Grid, Typography, Button } from "@mui/material";
import "./DashboardPersonal.css";
import { useSelector } from "react-redux";
import { editEmployeeData, fetchEmployeeData } from "../../EmpApiServices";
import { toast,ToastContainer } from "react-toastify";

const DashboardPersonal = () => {
  const { employeeId } = useSelector((state) => state.auth.user);

  const [formData, setFormData] = useState({
    title: "",
    firstName: "",
    lastName: "",
    middleName: "",
    employeeName: "",
    gender: "",
    password: "",
    mobile: "",
    designation: "",
    department: "",
    email: "",
    dob: "",
    education: "",
    employmentStartDate: "",
    probationStartDate: "",
    address: "",
    address1: "",
    address2: "",
    townCity: "",
    state: "",
    country: "",
    postcode: "",
    name: "",
    relation: "",
    contactNumber: "",
    nameOnAccount: "",
    nameOfBank: "",
    bankBranch: "",
    accountNumber: "",
    sortCodeOrIfscCode: "",
    taxCode: "",
    niNumber: "",
    passportNumber: "",
    countryOfIssue: "",
    passportExpiryDate: "",
    licenseNumber: "",
    countryOfIssue: "",
    licenseClass: "",
    dateOfExpiry: "",
    visaNumber: "",
    visaExpiryDate: "",
  });

  useEffect(() => {
    fetchEmployeeDetails();
  }, [employeeId]);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await fetchEmployeeData(employeeId);
      setFormData(response);
    } catch (error) {
      console.error("Failed to fetch employee details", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const keys = name.split(".");
    if (keys.length > 1) {
      setFormData((prevData) => ({
        ...prevData,
        [keys[0]]: {
          ...prevData[keys[0]],
          [keys[1]]: value,
        },
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = async () => {
    try {
       const response =  await editEmployeeData(employeeId, formData);
            toast.success(response.data.message);
            fetchEmployeeDetails(); 
    } catch (error) {
      console.error("Error updating details", error);
    }
  };
  

  return (
    <div className="dashboard-personal-form-container">
      <form className="dashboard-personal-form">
        <div className="EmolpyeePersonalDetails">
          <Typography
            style={{ fontSize: "1.25rem", fontWeight: "600" }}
            className="dashboard-personal-form-title"
          >
            Employee Basic Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="title"
                label="Title"
                value={formData.title || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="firstName"
                label="First Name"
                value={formData.firstName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="lastName"
                label="Last Name"
                value={formData.lastName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="middleName"
                label="Middle Name"
                value={formData.middleName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="gender"
                label="Gender"
                value={formData.gender}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                name="dob"
                label="Date of Birth"
                value={formData.dob}
                onChange={handleChange}
                required
              
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="email"
                label="Email Address"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="mobile"
                label="Mobile Number"
                value={formData.mobile}
                onChange={handleChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="jobTitle"
                label="Designation"
                value={formData.designation}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                name="startDate"
                label="Employee Start Date"
                value={formData.employmentStartDate}
                onChange={handleChange}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                name="probationStartDate"
                label="Probation Start Date"
                value={formData.probationStartDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className="EmployeeAddressDetails">
          <Typography
            style={{ fontSize: "1.25rem", fontWeight: "600" }}
            className="dashboard-personal-form-title"
          >
            Address Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address" // Corrected name
                label="Address"
                value={formData.address || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address1" // Corrected name
                label="Address 1"
                value={formData.address1 || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="address2" // Corrected name
                label="Address 2"
                value={formData.address2 || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="townCity" // Corrected name
                label="Town/City"
                value={formData.townCity || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="country" // Corrected name
                label="Country"
                value={formData.country || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="postCode" // Corrected name
                label="Postcode"
                value={formData.postcode || ""}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>

        <div>
          <Typography
            style={{ fontSize: "1.25rem", fontWeight: "600" }}
            className="dashboard-personal-form-title"
          >
            Emergency Contact
          </Typography>
          <div className="dashboard-personal-emergency-container">
            <span>
              Add an emergency contact in case something unexpected happens.
            </span>
          </div>
        </div>
        <div className="EmployeeBankDetails">
          <Typography
            style={{ fontSize: "1.25rem", fontWeight: "600" }}
            className="dashboard-personal-form-title"
          >
            Bank Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="nameOnAccount" // Corrected name
                label="Name on Account"
                value={formData.nameOnAccount || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="nameOfBank" // Corrected name
                label="Name of Bank"
                value={formData.nameOfBank || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="bankBranch" // Corrected name
                label="Bank Branch"
                value={formData.bankBranch || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="accountNumber" // Corrected name
                label="Account Number"
                value={formData.accountNumber || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="sortCodeOrIfscCode" // Corrected name
                label="Sort Code"
                value={formData.sortCodeOrIfscCode || ""}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>

        <div className="EmployeeSensitiveDetails">
          <Typography
            style={{ fontSize: "1.25rem", fontWeight: "600" }}
            className="dashboard-personal-form-title"
          >
            Sensitive Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="taxCode"
                label="Tax Code"
                value={formData.taxCode}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="niNumber"
                label="NI Number"
                value={formData.niNumber}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </div>
        <div className="EmployeePassportDetails">
          <Typography
            style={{ fontSize: "1.25rem", fontWeight: "600" }}
            className="dashboard-personal-form-title"
          >
            Passport
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="passportNumber"
                label="Passport Number"
                value={formData.passportNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="passportCountry"
                label="Country of Issue"
                value={formData.countryOfIssue}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                name="passportExpiryDate"
                label="Date of Expiry"
                value={formData.passportExpiryDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className="EmployeeDrivingDetails">
          <Typography
            style={{ fontSize: "1.25rem", fontWeight: "600" }}
            className="form-details-title"
          >
            Driving Licence
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="licenseNumber" // Corrected name
                label="Licence Number"
                value={formData.licenseNumber || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="countryOfIssue" // Corrected name
                label="Country of Issue"
                value={formData.countryOfIssue || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="licenseClass" // Corrected name
                label="Licence Class"
                value={formData.licenseClass || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                name="dateOfExpiry" // Corrected name
                label="Date of Expiry"
                value={formData.dateOfExpiry || ""}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </div>

        <div className="EmployeeVisaDetails">
          <Typography
            style={{ fontSize: "1.25rem", fontWeight: "600" }}
            className="form-details-title"
          >
            Visa
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                name="visaNumber"
                label="Visa Number"
                value={formData.visaNumber}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                type="date"
                name="visaExpiryDate"
                label="Visa Expiry Date"
                value={formData.visaExpiryDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <ToastContainer/>
          </Grid>
        </div>
        <Button variant="contained" onClick={handleSubmit}>
          Save Changes
        </Button>
      </form>
    </div>
  );
};

export default DashboardPersonal;
