import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import "./Schedule.css";
import Header from "../../components/Navbar/Navbar";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";

const getWeekRange = (startDate) => {
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 6);
  return `${startDate.toISOString().split('T')[0]} : ${endDate.toISOString().split('T')[0]}`;
};

const getWeeksOfMonth = (year, month) => {
  const weeks = [];
  const today = new Date();
  let date = new Date(year, month, 1);

  const firstDay = date.getDay();
  if (firstDay !== 1) {
    const diff = firstDay === 0 ? 1 : 8 - firstDay;
    date.setDate(date.getDate() + diff);
  }

  if (today.getFullYear() === year && today.getMonth() === month) {
    const todayWeekStart = new Date(today);
    todayWeekStart.setDate(today.getDate() - today.getDay() + 1); 
    weeks.push(getWeekRange(todayWeekStart));
    todayWeekStart.setDate(todayWeekStart.getDate() + 7); 
    date = todayWeekStart;
  }

  while (date.getMonth() === month || (date.getMonth() === (month + 1) % 12 && date.getDate() <= 6)) {
    weeks.push(getWeekRange(new Date(date)));
    date.setDate(date.getDate() + 7);
  }
  return weeks;
};

const getMonthsOfYear = (year) => {
  return Array.from({ length: 12 }, (_, i) => 
    new Date(year, i).toLocaleString("en-US", { month: "long" })
  );
};

const Schedule = ({ totalHours }) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().toLocaleString("en-US", { month: "long" });

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedWeek, setSelectedWeek] = useState("");
  const [weekRanges, setWeekRanges] = useState([]);
  const [monthOptions, setMonthOptions] = useState(getMonthsOfYear(currentYear));
  const [setTasks] = useState({});

  useEffect(() => {
    const monthIndex = monthOptions.indexOf(selectedMonth);
    const weeks = getWeeksOfMonth(selectedYear, monthIndex);
    setWeekRanges(weeks);

    if (weeks.length > 0) {
      setSelectedWeek(weeks[0]);
    }
  }, [selectedMonth, selectedYear, monthOptions]);

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
    setMonthOptions(getMonthsOfYear(newYear));
    setSelectedMonth(new Date().toLocaleString("en-US", { month: "long" }));
  };

 
  const copyTasksFromPreviousWeek = () => {
    if (!selectedWeek) {
      alert("No week selected.");
      return;
    }

    const selectedWeekIndex = weekRanges.indexOf(selectedWeek);
    if (selectedWeekIndex === -1 || selectedWeekIndex === 0) {
      alert("No previous data for week to copy.");
      return;
    }

    const previousWeek = weekRanges[selectedWeekIndex - 1];
    setTasks((prevTasks) => ({
      ...prevTasks,
      [selectedWeek]: prevTasks[previousWeek] || [],
    }));
  };

  return (
    <>
      <Header siteName="Timesheet" showLinks={["timesheets"]} />
      <div className="schedule-container">
        <div className="dropdowns">
          {/* Year Selection */}
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Select Year</InputLabel>
            <Select value={selectedYear} onChange={handleYearChange} label="Select Year">
              <MenuItem value={currentYear}>{currentYear}</MenuItem>
              <MenuItem value={currentYear + 1}>{currentYear + 1}</MenuItem>
            </Select>
          </FormControl>

          {/* Month Selection */}
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Select Month</InputLabel>
            <Select value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)} label="Select Month">
              {monthOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Week Selection */}
          <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel>Select Week</InputLabel>
            <Select value={selectedWeek} onChange={(e) => setSelectedWeek(e.target.value)} label="Select Week">
              {weekRanges.map((range, index) => (
                <MenuItem key={index} value={range}>
                  {range}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="TotalReported">
          <DynamicButton text="Copy Tasks from Previous Week" onClick={copyTasksFromPreviousWeek} height="50px" backgroundColor="#6674a9" color="white" />
          <div className="indicators">
            <p>Total Reported Hrs: {totalHours.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Schedule;