import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Eye } from "lucide-react";
import { useSelector } from "react-redux";
import { fetchEmployeeById } from "../../EmpApiServices";
import "./Dashboard-1.css";
import Header from "../../components/Navbar/Navbar";
import CommonHeader from "../../../components/CommonHeader";
import LinearIndeterminate from "../../../components/Linearindeterminate/Linearindeterminate";
const profileImage = "/assets/images/profile.jpg";

const Dashboard = () => {
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(true);

  const { employeeId } = useSelector((state) => state.auth.user);

  const handleVisibilityClick = (teamMember) => {
    setSelectedTeamMember(teamMember);
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setSelectedTeamMember(null);
  };
  const fetchEmployeeData = async (id) => {
    setLoading(true);
    try {
      const data = await fetchEmployeeById(id);
      const employeeArray = Array.isArray(data) ? data : [data];
      setFilteredDocuments(employeeArray.filter(Boolean)); 
    } catch (error) {
      console.error("Error fetching employee data:", error);
      setFilteredDocuments([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (employeeId) {
      fetchEmployeeData(employeeId);
    }
  }, [employeeId]);
  const handleRefreshClick = () => {
    fetchEmployeeData(employeeId);
  };
  return (
    <>
      <Header
        siteName={" Account"}
        profileImage={profileImage}
        showLinks={["account"]}
      />
      <div className="dashboard-container">
        <CommonHeader
          showIcons={{ plus: false, trash: false, rotate: true }}
          showSearchFilter={false}
          handleRefreshClick={handleRefreshClick}
        />
        {loading ? (
          <LinearIndeterminate />
        ) : (
          <div className="dashboard-grid">
            {filteredDocuments.map((teamMember) => (
              <div
                key={teamMember.id || teamMember._id}
                className="dashboard-card"
              >
                <div className="card-content">
                  <div className="profile-info">
                    <h3 className="employee-name">{teamMember.employeeName}</h3>
                    <div className="action-buttons">
                      <Link
                        to={`/employee/dashboardpersonal/${
                          teamMember.id || teamMember._id
                        }`}
                        className="view-details-link"
                      >
                        View full details
                      </Link>
                      <button
                        onClick={() => handleVisibilityClick(teamMember)}
                        className="visibility-button"
                      >
                        <Eye size={20} />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}{" "}
        {showPopup && selectedTeamMember && (
          <div className="modal-overlay">
            <div className="modal-content">
              <h2 className="modal-title">{selectedTeamMember.employeeName}</h2>
              <button onClick={closePopup} className="modal-close-button">
                Close
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
