import React from 'react';

const Account = () => {

  return <div>
   
  </div>;
};

export default Account;
