

import React, { useState } from "react";
import "./Login.css"; 
import loginpage from "../../../components/assets/Icons/loginpage.png"; 
import companyLogo from "../../../components/assets/Icons/hirefleX247.com-dark.png";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Adminloginvalidation,
  SuperAdminloginvalidation,
} from "../../../Utils/validation"; 
import { loginvalidation } from "../../../Utils/validation"; 
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { loginSuperAdmin } from "../../../SuperAdmin/ApiServices";
import { loginEmployee } from "../../EmpApiServices";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../../Redux/authSlice/authSlice";
import {
  TextField,
  
  
  Link,
} from "@mui/material";
import { loginAdmin } from "../../../Admin/APIServices";
import DynamicButton from "../../../components/DynamicButton/DynamicButton";

const Login = () => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");

  const [employeeId, setEmployeeId] = useState("");
  const [password, setPassword] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("Employee");
  const [apiError, setApiError] = useState("");
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({}); 
    setApiError(""); 
    setIsSubmitting(true);

    try {
      if (selectedUserType === "Super Admin") {
        // Super Admin Login
        await SuperAdminloginvalidation.validate(
          { email, password },
          { abortEarly: false }
        );
        const data = await loginSuperAdmin({ email, password });
        if (data.jwtToken) {
          localStorage.setItem("jwtToken", data.jwtToken);
          toast.success("Super Admin Login Success");
          navigate("/superadmin/dashboard");
        }
      } else if (selectedUserType === "Employee") {
        // Employee Login
        await loginvalidation.validate(
          { employeeId, password },
          { abortEarly: false }
        );
        const data = await loginEmployee(employeeId, password);
        const { token,user } = data;
        localStorage.setItem("token", token);
        dispatch(loginSuccess({ user: user }));
        navigate("/dashboard");
        toast.success("Login successful");
      } else if (selectedUserType === "Admin") {
        // Admin Login
        await Adminloginvalidation.validate(
          { username, password },
          { abortEarly: false }
        );
        const data = await loginAdmin({ username, password });
        if (data.jwtToken) {
          localStorage.setItem("jwtToken", data.jwtToken);
          toast.success("Admin Login Success");
          navigate("/admin/dashboard");
        }
      } else {
        throw new Error("Invalid login credentials");
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const formattedErrors = error.inner.reduce((acc, err) => {
          acc[err.path] = err.message;
          return acc;
        }, {});
        setErrors(formattedErrors);
      } else {
        const errorMessage =
          error.response?.data?.error || "Invalid username or password";
        setApiError(errorMessage);
        toast.error(errorMessage);
      }
    } finally {
      setIsSubmitting(false); 
    }
  };

  const handleUserTypeClick = (userType) => {
    setSelectedUserType(userType);
  };

  return (
    <div className="login-container">
      <div className="LoginPageBox">
      <div className="left-section">
        <div className="company-header-container">
          {/* <img src={companyLogo} alt="Hireflex Logo" className="logo" /> */}
        </div>
        <div className="image-section">
          <img src={loginpage} alt="Login Illustration" />
        </div>
      </div>
      <div className="right-section">
        <div className="login-header">
          <h1>
            {/* Welcome to{" "} */}
            <img
              src={companyLogo}
              alt="company-logo"
              className="company-logo1"
            />
          </h1>
        </div>
        <div className="UserSelectButton">
          <div className="user-type-selector">
            <DynamicButton
              text="Employee"
              onClick={() => handleUserTypeClick("Employee")}
              height="35px"
              width="150px"
              backgroundColor={
                selectedUserType === "Employee" ? "#57002c" : "black"
              }
              color="white"
              isActive={selectedUserType === "Employee"}
            />
            <DynamicButton
              text="SuperAdmin"
              onClick={() => handleUserTypeClick("Super Admin")}
              height="35px"
              width="150px"
              backgroundColor={
                selectedUserType === "Super Admin" ? "#57002c" : "black"
              }
              color="white"
              isActive={selectedUserType === "Super Admin"}
            />
            <DynamicButton
              text="Admin"
              onClick={() => handleUserTypeClick("Admin")}
              height="35px"
              width="150px"
              backgroundColor={
                selectedUserType === "Admin" ? "#57002c" : "black"
              }
              color="white"
              isActive={selectedUserType === "Admin"}
            />
          </div>
        </div>
        {selectedUserType === "Super Admin" && (
          <div className="EmployeeLogin">
            <div className="login-background">
              <Box className="login-form-container">
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Enter Email"
                    variant="outlined"
                    className="custom-text-field"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
         
                  />
                  <TextField
                    label="Enter Password"
                    variant="outlined"
                    className="custom-text-field"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                  />
                  {apiError && (
                    <Typography color="error" variant="body2">
                      {apiError}
                    </Typography>
                  )}
                  <div className="mt-3">
                    <button
                      type="submit"
                      variant="contained"
                      fullWidth
                      className="login-button"
                      disabled={isSubmitting}
                    >
                      Login
                    </button>
                  </div>
                </form>
                <div className="text-black p-1 mt-4">
                  <Typography variant="body2">
                    <Link to="/register" style={{ cursor: 'pointer' }}>
                      Need assistance with Login/Registration? <br />
                    </Link>
                  </Typography>
                  <Typography variant="body2" style={{ cursor: 'pointer' }}>
                    Email: hr@hireflex247.com
                  </Typography>
                </div>
              </Box>
              <ToastContainer />
            </div>
          </div>
        )}
        {selectedUserType === "Admin" && (
          <div className="EmployeeLogin">
            <div className="login-background">
              <Box className="login-form-container">
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Enter username"
                    variant="outlined"
                    className="custom-text-field"
                    fullWidth
                    margin="normal"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    error={Boolean(errors.username)}
                    helperText={errors.username}
                  />
                  <TextField
                    label="Enter Password"
                    variant="outlined"
                    className="custom-text-field"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                  />
                  {apiError && (
                    <Typography color="error" variant="body2">
                      {apiError}
                    </Typography>
                  )}
                  <div className="mt-3">
                    <button
                      type="submit"
                      variant="contained"
                      fullWidth
                      className="login-button"
                      disabled={isSubmitting}
                    >
                      Login
                    </button>
                  </div>
                </form>
                <div className="text-black p-1 mt-4">
                  <Typography variant="body2">
                    <Link to="/register" style={{ cursor: 'pointer' }}>
                      Need assistance with Login/Registration? <br />
                    </Link>
                  </Typography>
                  <Typography variant="body2" style={{ cursor: 'pointer' }}>
                    Email: hr@hireflex247.com
                  </Typography>
                </div>
              </Box>
              <ToastContainer />
            </div>
          </div>
        )}
        {selectedUserType === "Employee" && (
          <div className="EmployeeLogin">
            <div className="login-background">
              <Box className="login-form-container">
                <form onSubmit={handleSubmit}>
                  <TextField
                    label="Enter EmployeeId"
                    variant="outlined"
                    className="custom-text-field"
                    fullWidth
                    margin="normal"
                    value={employeeId}
                    onChange={(e) => setEmployeeId(e.target.value)}
                    error={Boolean(errors.employeeId)}
                    helperText={errors.employeeId}
                  />
                  <TextField
                    label="Enter Password"
                    variant="outlined"
                    className="custom-text-field"
                    fullWidth
                    margin="normal"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={Boolean(errors.password)}
                    helperText={errors.password}
                  />
                
                  <div className="mt-3">
                    <button
                      type="submit"
                   
                      fullWidth
                      className="login-button"
                      disabled={isSubmitting}
                    >
                      Login
                    </button>
                  </div>
                </form>
                <div className="text-black p-1 mt-4 ">
                 
                    <Typography variant="body2">
                    <Link to="#" style={{ cursor: 'pointer' }}>
                      Need assistance helps? <br />
                    </Link>
                  </Typography>
                  
                  <Typography variant="body2">
                  <Link to="#" style={{ cursor: 'pointer' }}>
                    Email: hr@hireflex247.com
                    </Link>
                  </Typography>
                </div>
              </Box>
              <ToastContainer />
            </div>
          </div>
        )}
      </div>
      </div>
    </div>
  );
};

export default Login;
